<template>
  <v-card
    v-if="data.invoiceData"
    class="app-invoice-editable"
  >
    <!-- Header -->
    <v-card-text class="py-9 px-8">
      <div class="mb-6">
        <v-img
          :src="require('@/assets/images/vorkum-logo-header.png')"
          max-width="200px"
          alt="logo"
          contain
          class="me-3"
        ></v-img>
      </div>
      <div class="invoice-header d-flex flex-wrap justify-space-between">
        <!-- Left Content -->
        <div v-if="data.invoiceData.issuer == 'SP'">
          <span class="d-block"><strong>VORKUM Žiga Macedoni s.p.</strong></span>
          <span class="d-block">Štajerska cesta 20</span>
          <span class="d-block">1231 Ljubljana-Črnuče</span>
          <span class="d-block">Slovenija</span>
        </div>
        <div v-else>
          <span class="d-block"><strong>vorkum d.o.o.</strong></span>
          <span class="d-block">ulica bratov Kastelic 6</span>
          <span class="d-block">1291 Škofljica</span>
          <span class="d-block">Slovenija</span>
        </div>
        <!-- Right Content -->
        <div class="text-right">
          <span class="d-block">www.vorkum.si</span>
          <span class="d-block">info@vorkum.si</span>
          <span class="d-block">+386 (0) 41 224 254</span>
        </div>
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <!-- Payment Details -->
    <v-card-text class="py-9 px-8">
      <div class="d-flex justify-space-between flex-wrap flex-column flex-sm-row">
        <div class="mb-6 mb-sm-0">
          <p class="font-weight-semibold payment-details-header">
            Invoice To:
          </p>
          <div class="d-flex">
            <v-autocomplete
              v-model="data.invoiceData.customer"
              outlined
              :menu-props="{ offsetY: true }"
              :items="customers"
              dense
              :filter="customerFilter"
              item-text="company_name"
              item-value="customer_id"
              return-object
              placeholder="Select Customer"
              class="select-invoice-to"
              @change="checkIfCanSave"
            ><template slot="selection" slot-scope="data">
              {{ data.item.company_name }} (<small>{{ data.item.contact_person }}</small>)
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.company_name }} (<small>{{ data.item.contact_person }}</small>)
            </template></v-autocomplete>
            <v-btn
              color="primary"
              class="ml-3"
              fab
              dark
              small
              @click="addCustomer"
            >
              <v-icon
                size="18"
              >
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </div>

          <v-dialog
            v-model="formDialog"
            max-width="500px"
          >
            <customer-form
              :customer="customerData"
              @close-dialog="formDialog = false"
              @refresh-list="fetchCustomers"
            ></customer-form>
          </v-dialog>

          <p class="mb-1">
            {{ data.invoiceData.customer.contact_person }}
          </p>
          <p class="mb-1">
            {{ data.invoiceData.customer.company_name }}
          </p>
          <p
            v-if="data.invoiceData.customer.address"
            class="mb-1"
          >
            {{ data.invoiceData.customer.address }}, {{ data.invoiceData.customer.country }}
          </p>
          <p class="mb-1">
            {{ data.invoiceData.customer.contact_person }}
          </p>
          <p class="mb-0">
            {{ data.invoiceData.customer.contact_person }}
          </p>
        </div>
        <div>
          <div class="mb-4 d-flex align-center justify-end">
            <span class="me-2 font-weight-medium text-xl text--primary">{{ $t('Invoice' )}}:</span>
            <v-text-field
              v-model="invoiceNumber"
              outlined
              dense
              class="header-inputs flex-grow-0"
              hide-details="auto"
            ></v-text-field>
          </div>
          <div class="mb-4 d-flex align-center justify-end">
            <span class="me-2">Billing place:</span>
            <v-text-field
              v-model="data.invoiceData.billing_place"
              outlined
              dense
              class="header-inputs flex-grow-0"
              hide-details="auto"
            ></v-text-field>
          </div>
          <div class="mb-4 d-flex align-center justify-end">
            <span class="me-2">Date Issued: </span>
            <v-menu
              v-model="isIssueDateMenuOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              eager
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data.invoiceData.billing_date"
                  class="header-inputs flex-grow-0"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.invoiceData.billing_date"
                @input="isIssueDateMenuOpen = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="mb-4 d-flex align-center justify-end">
            <span class="me-2">Delivery Date: </span>
            <v-menu
              v-model="isDeliveryDateMenuOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              eager
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data.invoiceData.delivery_date"
                  class="header-inputs flex-grow-0"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.invoiceData.delivery_date"
                @input="isDeliveryDateMenuOpen = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="mb-2 d-flex align-center justify-end">
            <span class="me-2">Due Date: </span>
            <v-menu
              v-model="isDueDateMenuOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              eager
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data.invoiceData.due_date"
                  class="header-inputs flex-grow-0"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.invoiceData.due_date"
                @input="isDueDateMenuOpen = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <!-- Add purchased products -->
    <div class="add-products-form pt-9 pb-10 px-8">
      <div class="add-products-header mb-2 d-none d-md-flex">
        <div class="px-5 flex-grow-1 font-weight-semibold">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <span>Item</span>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <span>Unit price</span>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <span>Vat</span>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <span>Quantity</span>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <span>Price</span>
            </v-col>
          </v-row>
        </div>
        <div class="header-spacer"></div>
      </div>
      <div
        v-for="(purchasedProduct, purchasedProductIndex) in data.invoiceData.line_items"
        :key="purchasedProductIndex"
        class="single-product-form"
      >
        <v-card
          outlined
          class="d-flex mb-2"
        >
          <!-- Left Form -->
          <div class="pa-5 flex-grow-1">
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model.number="purchasedProduct.label"
                  outlined
                  dense
                  type="text"
                  hide-details="auto"
                  label="Item"
                  placeholder=""
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
                sm="4"
              >
                <v-text-field
                  v-model.number="purchasedProduct.unit_price"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  label="Unit price"
                  @input="val => { purchasedProduct.unit_price = Math.abs(val) }"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
                sm="4"
              >
                <v-text-field
                  v-model.number="purchasedProduct.vat_rate"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  label="Vat rate"
                  @input="val => { purchasedProduct.vat_rate = Math.abs(val) }"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
                sm="4"
              >
                <v-text-field
                  v-model.number="purchasedProduct.quantity"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  label="Quantity"
                  @input="val => { purchasedProduct.quantity = Math.abs(val) }"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
                sm="4"
              >
                <p class="my-2">
                  <span class="d-inline d-md-none">Price: </span>
                  <span>{{ formatCurrency(lineItemSum(purchasedProduct), data.invoiceData.currency) }}</span>
                </p>
              </v-col>
            </v-row>
          </div>
          <!-- Item Actions -->
          <div class="d-flex flex-column item-actions rounded-0 pa-1">
            <v-btn
              icon
              small
              @click="data.invoiceData.line_items.splice(purchasedProductIndex, 1)"
            >
              <v-icon size="20">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </div>
        </v-card>
      </div>
      <v-btn
        color="primary"
        class="mt-4"
        outlined
        @click="addNewItem"
      >
        Add Item
      </v-btn>
    </div>

    <v-divider></v-divider>

    <!-- Total -->
    <v-card-text class="py-9 px-8">
      <div class="d-flex justify-space-between flex-wrap flex-column flex-sm-row">
        <div class="mb-6 mb-sm-0">

        </div>
        <div>
          <table class="w-full">
            <tr>
              <td class="pe-16">
                Subtotal:
              </td>
              <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                {{ formatCurrency(invoiceSubTotal, data.invoiceData.currency) }}
              </th>
            </tr>
            <tr>
              <td class="pe-16">
                Tax:
              </td>
              <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                {{ formatCurrency(invoiceVatTotal) }}
              </th>
            </tr>
          </table>
          <v-divider class="mt-4 mb-3"></v-divider>
          <table class="w-full">
            <tr>
              <td class="pe-16">
                Total:
              </td>
              <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                {{ formatCurrency(invoiceTotal, data.invoiceData.currency) }}
              </th>
            </tr>
          </table>
        </div>
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-text class="px-8 py-6">
      <div class="mb-0">
        <p class="font-weight-semibold mb-2">
          Note:
        </p>
        <v-textarea
          v-model="data.invoiceData.footer_note"
          outlined
          rows="3"
          hide-details="auto"
          placeholder=""
        ></v-textarea>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import themeConfig from '@themeConfig'
import { ref, onUnmounted, computed, watch } from '@vue/composition-api'
import store from '@/store'
import { mdiClose, mdiCogOutline, mdiPlus } from '@mdi/js'
import invoiceStoreModule from './invoiceStoreModule'
import { formatCurrency, lineItemSum, formatNumber } from '@core/utils/filter'

import CustomerForm from '@/views/apps/customers/CustomerForm.vue'

export default {
  components: {
    CustomerForm
  },
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  setup(props, {emit}) {
    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    const invoiceSubTotal = computed(() => {
      let _total = 0;
      props.data.invoiceData.line_items.map((line_item) => {
        _total += parseFloat(line_item.unit_price) * parseFloat(line_item.quantity)
      });
      return _total.toFixed(2)
    })

    const invoiceTotal = computed(() => {
      let _total = 0;
      props.data.invoiceData.line_items.map((line_item) => {
        _total += lineItemSum(line_item)
      });
      return _total.toFixed(2)
    })

    const invoiceVatTotal = computed(() => {
      return invoiceTotal.value - invoiceSubTotal.value
    })

    const invoiceNumber = computed({
      get: () => props.data.invoiceData.invoice_number + "/" + props.data.invoiceData.invoice_year,
      set: val => {
        let nums = val.split("/")
        props.data.invoiceData.invoice_number = nums[0]
        props.data.invoiceData.invoice_year = nums[1]
      }
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const isIssueDateMenuOpen = ref(false)
    const isDueDateMenuOpen = ref(false)
    const isDeliveryDateMenuOpen = ref(false)

    const customers = ref([])
    const fetchCustomers = () => {
      store.dispatch('app-invoice/fetchClients').then(response => {
        customers.value = response.data
      })
    }

    fetchCustomers()

    const customerFilter = (item,queryText,itemText) => {
      return (
        item.company_name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.contact_person.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    }

    const addNewItem = () => {
      props.data.invoiceData.line_items.push({
        label: '',
        unit_price: 0,
        quantity: 0,
        vat_rate: 0
      })
    }

    const checkIfCanSave = () => {
      if (props.data.invoiceData.customer.id && invoiceTotal.value > 0) {
        emit('enable-save')
      }
    }

    watch(invoiceTotal, () => {
      if (props.data.invoiceData.customer.id && invoiceTotal.value > 0) {
        emit('enable-save')
      } else {
        emit('disable-save')
      }
    })

    const formDialog = ref(false)
    const customerData = ref({})

    const addCustomer = () => {
      formDialog.value = true
    }

    return {
      isIssueDateMenuOpen,
      isDueDateMenuOpen,
      isDeliveryDateMenuOpen,
      customers,
      invoiceNumber,
      addNewItem,
      formatCurrency,
      invoiceSubTotal,
      invoiceTotal,
      invoiceVatTotal,
      lineItemSum,
      formatNumber,
      customerFilter,
      checkIfCanSave,
      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      formDialog,
      customerData,
      addCustomer,
      fetchCustomers,
      // Icons
      icons: {
        mdiClose,
        mdiCogOutline,
        mdiPlus
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 350px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        /* margin-top: 2rem; */
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      justify-content: center;
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
