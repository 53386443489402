<template>
  <v-card>
    <v-card-title>Settings</v-card-title>
    <v-card-text>
      <p class="payment-details-header">Issuer:</p>
      <v-select
        v-model="data.invoiceData.issuer"
        outlined
        :items="issuers"
        dense
        placeholder="Select Issuer"
        :menu-props="{ offsetY: true }"
        hide-details="auto"
        class="mb-6"
      ></v-select>
      <p class="payment-details-header mt-5">Language:</p>
      <v-select
        v-model="data.invoiceData.lang"
        outlined
        :items="languages"
        dense
        placeholder="Select Language"
        :menu-props="{ offsetY: true }"
        hide-details="auto"
        class="mb-6"
      ></v-select>
      <p class="payment-details-header">Currency:</p>
      <v-select
        v-model="data.invoiceData.currency"
        outlined
        :items="currencies"
        dense
        placeholder="Select Currency"
        :menu-props="{ offsetY: true }"
        hide-details="auto"
        class="mb-6"
      ></v-select>
      <div class="d-flex align-center justify-space-between mb-3">
        <span>Paid</span>
        <v-switch
          hide-details="auto"
          class="mt-0"
          v-model="data.invoiceData.paid"
        ></v-switch>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  setup(props) {

    const issuers = [{
      value: "DOO",
      text: 'vorkum d.o.o.'
    },{
      value: "SP",
      text: 'VORKUM Žiga Macedoni s.p.'
    }]

    const languages = [{
      value: "SI",
      text: "Slovenian"
    }, {
      value: "EN",
      text: "English"
    }]

    const currencies = [{
      value: "EUR",
      text: "Euro (€)"
    }, {
      value: "USD",
      text: "US Dollar ($)"
    }]

    return {
      issuers,
      languages,
      currencies,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
