import axios from '@axios'
import themeConfig from '@themeConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(themeConfig.api_prefix + '/invoices', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchNewInvoiceNumber(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(themeConfig.api_prefix + '/invoices/new_num', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(themeConfig.api_prefix + `/invoices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPdf(ctx, ids ) {
      return new Promise((resolve, reject) => {
        axios
          .post(themeConfig.api_prefix + `/invoices/pdf`, {ids: ids} , { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInvoice(ctx, invoice ) {
      let invoice_id = invoice.hasOwnProperty('invoiceData') ? invoice.invoiceData.id : invoice.id
      return new Promise((resolve, reject) => {
        axios
          .post(themeConfig.api_prefix + `/invoices/${invoice_id}/edit`, invoice )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addInvoice(ctx, invoice ) {
      return new Promise((resolve, reject) => {
        axios
          .post(themeConfig.api_prefix + `/invoices`, invoice )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    duplicateInvoice(ctx, id ) {
      return new Promise((resolve, reject) => {
        axios
          .get(themeConfig.api_prefix + `/invoices/${id}/duplicate`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteInvoice(ctx, id ) {
      return new Promise((resolve, reject) => {
        axios
          .delete(themeConfig.api_prefix + `/invoices/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(themeConfig.api_prefix + '/customers',{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDraft(ctx, draft ) {
      return new Promise((resolve, reject) => {
        axios
          .post(themeConfig.api_prefix + `/drafts`, draft )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDrafts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(themeConfig.api_prefix + '/drafts',{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDraft(ctx, id ) {
      return new Promise((resolve, reject) => {
        axios
          .delete(themeConfig.api_prefix + `/drafts/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createInvoiceFromDraft(ctx, draft ) {
      return new Promise((resolve, reject) => {
        axios
          .post(themeConfig.api_prefix + `/drafts/create_invoice`, draft )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDraft(ctx, draft ) {
      return new Promise((resolve, reject) => {
        axios
          .post(themeConfig.api_prefix + `/drafts/${draft.id}/edit`, draft )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
